@import '@/assets/stylesheets/common/variables';

.container {
  background-color: #0d1a32;
  box-sizing: border-box;
  margin: 0;
  @include unusedMedia(phone, large-phone, tablet) {
    padding-top: 0;
    width: fit-content;
  }

  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: $black-text;
  line-height: 1.15;

  h1 {
    font-size: 60px;
    font-weight: 600;
    @include unused-for-phone {
      font-size: 25px;
    }
  }

  h2 {
    font-size: 45px;
    font-weight: 500;
    @include unused-for-phone {
      font-size: 25px;
    }
  }

  h3 {
    font-size: 30px;
    font-weight: 500;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a {
    color: $brand-primary;
  }

  hr {
    margin: 40px 0;
  }

  @include unused-for-phone {
    font-size: 16px;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}

.el-pagetitle h3 {
  margin-top: 0;
  @include unused-for-phone {
    font-size: 20px;
  }
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.minHeight100 {
  min-height: 100vh;
}

.content {
  color: white;
  width: 720px;
  max-width: 96%;
  margin: 16px auto;
}

.temp_modal {
  position: absolute;
  top: 16px;
  left: 16px;
  min-height: 360px;
  width: 96%;
  background: #166781;
  color: white;
}

.temp_backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  min-height: 100vh;
  z-index: 100;
}

.block {
  display: block;
}
